<template>
  <div class="landing-page-collections">
    <curated-collections class="mt-16 md:mt-0" :show-spotlight="false" :collections="collections" :modular-content="modularContent" :intro="page.elements.intro.value" :heading="page.elements.heading.value" />
  </div>
</template>

<script>
import CuratedCollections from "@/components/CuratedCollections";
export default {
  name: 'LandingPageCollections',
  components: {CuratedCollections},
  props: {
    page: {
      type: Object,
      default () {
        return null
      }
    },
    modularContent: {
      type: Object,
      default () {
        return null
      }
    }
  },
  computed: {
    collections () {
      let c = []
      for (let i = 0; i < this.page.elements.collections.value.length; i++) {
        c.push(this.modularContent[this.page.elements.collections.value[i]]);
      }
      return c
    }
  }
}
</script>

<style lang="scss">
.landing-page-collections {
  .curated-collections, .flex.flex-row.justify-between {
    margin-top: 0 !important;
  }
}
</style>
