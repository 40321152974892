<template>
  <div class="landing-page-hero">
    <div class="outer-container">
      <div class="inner-container">
        <div class="foreground">
          <h1 :class="{'vietnamese': language === 'vietnamese'}">{{page.elements.h1_title.value}}</h1>
          <div class="cta" v-if="page.elements.cta_text.value && !page.elements.cta_type.value.length">
            <router-link class="btn-white btn-wide inline-block" :to="page.elements.cta_url.value" v-if="page.elements.cta_url.value.substring(0, 1) === '/' && (page.elements.cta_type.value.length === 0 || page.elements.cta_type.value[0].codename === 'same_window')">{{page.elements.cta_text.value}}</router-link>
          </div>
          <div class="cta" v-if="page.elements.cta_text.value && page.elements.cta_type.value.length">
            <router-link class="btn-white btn-wide inline-block" :to="page.elements.cta_url.value" v-if="page.elements.cta_url.value.substring(0, 1) === '/' && page.elements.cta_type.value[0].codename === 'same_window'">{{page.elements.cta_text.value}}</router-link>
            <a class="btn-white btn-wide inline-block" :href="page.elements.cta_url.value" v-else :target="{'_blank': page.elements.cta_type.value[0].codename === 'new_window', '_self': page.elements.cta_type.value[0].codename === 'same_window'}">{{page.elements.cta_text.value}}</a>
          </div>
        </div>
        <bleed-container bleed="left">
          <div class="background" v-if="page.elements.background_image.value.length">
            <img :src="`${page.elements.background_image.value[0].url}?w=2000&auto=format`">
          </div>
        </bleed-container>
      </div>
    </div>
  </div>
</template>

<script>
import BleedContainer from "@/components/BleedContainer";
export default {
  name: 'LandingPageHero',
  components: {BleedContainer},
  props: {
    page: {
      type: Object,
      default () {
        return null
      }
    },
    modularContent: {
      type: Object,
      default () {
        return null
      }
    },
    language: {
      type: String,
      default: 'english'
    }
  }
}
</script>

<style lang="scss" scoped>
.landing-page-hero {
  padding-top: 90px;
  z-index: 1;

  @media(min-width: 768px)
  {
    padding-top: 115px;
  }

  @media(min-width: 1024px)
  {
    padding-top: 140px;
  }
}

.foreground {
  padding: 30px 0;
  position: absolute;
  z-index: 2;
  top: -90px;
  left: 20px;
  right: 20px;

  @media(min-width: 768px)
  {
    top: -115px;
  }

  @media(min-width: 1024px)
  {
    top: -140px;
  }
}

.cta {
  margin-top: 30px;
}

.inner-container {
  position: relative;
}

.bleed-container {
  position: relative;
  z-index: 1;
}

.background {
  height: 300px;
  position: relative;

  @media(min-width: 768px)
  {
    height: 400px;
  }

  @media(min-width: 1024px)
  {
    height: 680px;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

h1 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 36px;
  max-width: 720px;
  line-height: 1;

  @media(min-width: 768px)
  {
    font-size: 52px;
  }

  @media(min-width: 1024px)
  {
    font-size: 68px;
  }
}

h1.vietnamese {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 30px;
  max-width: 720px;
  line-height: 1.3;

  @media(min-width: 768px)
  {
    font-size: 40px;
  }

  @media(min-width: 1024px)
  {
    font-size: 50px;
  }
}
</style>
