<template>
  <div class="landing-page-video">
    <div class="outer-container">
      <div class="inner-container">
        <youtube-video v-if="page.elements.video_type.value[0].codename === 'youtube'" :video-id="page.elements.video_id.value" />
        <div class="vimeo-container" v-if="page.elements.video_type.value[0].codename === 'vimeo'">
          <vue-vimeo-player :video-id="page.elements.video_id.value" player-width="100%" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YoutubeVideo from "@/components/YoutubeVideo";
import { vueVimeoPlayer } from 'vue-vimeo-player'
export default {
  name: 'LandingPageVideo',
  components: {YoutubeVideo, vueVimeoPlayer },
  props: {
    page: {
      type: Object,
      default () {
        return null
      }
    },
    modularContent: {
      type: Object,
      default () {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .download {
    .icon {
      color: #000;
    }
  }
</style>

<style lang="scss">
  .vimeo-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
</style>
