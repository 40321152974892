<template>
  <div class="landing-page-text">
    <div class="outer-container">
      <div class="inner-container">
        <div class="text" v-html="page.elements.text.value"></div>
        <div class="cta" v-if="page.elements.cta_text.value && !page.elements.cta_type.value.length">
          <router-link class="btn-white btn-wide inline-block" :to="page.elements.cta_url.value" v-if="page.elements.cta_url.value.substring(0, 1) === '/' && (page.elements.cta_type.value.length === 0 || page.elements.cta_type.value[0].codename === 'same_window')">{{page.elements.cta_text.value}}</router-link>
        </div>
        <div class="cta" v-if="page.elements.cta_text.value && page.elements.cta_type.value.length">
          <router-link class="btn-white btn-wide inline-block" :to="page.elements.cta_url.value" v-if="page.elements.cta_url.value.substring(0, 1) === '/' && page.elements.cta_type.value[0].codename === 'same_window'">{{page.elements.cta_text.value}}</router-link>
          <a class="btn-white btn-wide inline-block" :href="page.elements.cta_url.value" v-else :target="{'_blank': page.elements.cta_type.value[0].codename === 'new_window', '_self': page.elements.cta_type.value[0].codename === 'same_window'}">{{page.elements.cta_text.value}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingPageText',
  props: {
    page: {
      type: Object,
      default () {
        return null
      }
    },
    modularContent: {
      type: Object,
      default () {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text {
  max-width: 780px;
}

.cta {
  margin-top: 30px;
}
</style>
