<template>
  <div class="landing-page">
    <div class="loading" v-if="!page"></div>
    <div v-else :class="{'font-viet': page.elements.custom_language && page.elements.custom_language.value.length && page.elements.custom_language.value[0].codename === 'vietnamese'}">

      <landing-page-hero :page="page" :modular-content="modularContent" :language="language" />
      <div class="outer-container">
        <div class="inner-container">
          <div class="intro-section">
            <bleed-container bleed="left" class="intro-container">
              <div class="bleed-wrapper">
                <div class="intro-paragraph" v-if="page.elements.intro_paragraph.value">
                  {{ page.elements.intro_paragraph.value }}
                </div>
                <landing-page-widgets :widgets="page.elements.intro_widgets.value" :modular-content="modularContent" />
              </div>
            </bleed-container>
          </div>
        </div>

      </div>
      <div class="main-section">
        <landing-page-widgets :widgets="page.elements.main_widgets.value" :modular-content="modularContent" />
      </div>
      <div class="white-section" v-if="page.elements.show_register_panel.value.length">
        <div class="outer-container">
          <div class="inner-container">
            <div class="image">
              <img src="@/assets/landing-register.jpg">
            </div>

            <div class="heading uppercase">
              Register now to begin—
            </div>

            <div class="description">
              <div>CONNECT is a dynamic, always-on, virtual platform bringing the global wine community together to experience Australia’s innovative wine scene in entirely new ways.</div>

              <div class="cta flex flex-row space-x-8">
                <button type="button" @click="register" class="btn-black flex-1">Sign Up</button>
                <button type="button" @click="login" class="btn-white-on-white flex-1">Login</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Kontent from "@/kontent";
import LandingPageHero from "@/components/LandingPageHero";
import LandingPageWidgets from "@/components/LandingPageWidgets";
import BleedContainer from "@/components/BleedContainer";
import Analytics from '@/analytics'
export default {
  name: 'LandingPage',
  metaInfo () {
    if (!this.page) {
      return null
    }

    let meta = [
      {property: 'og:site_name', content: 'CONNECT'},
      {property: 'og:url', content: window.location.href},
      {property: 'og:title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
      {property: 'og:description', content: this.page.elements.page__page_description.value},
      {name: 'title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
      {name: 'keywords', content: this.page.elements.page__meta_keywords.value},
      {name: 'description', content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value},
      {name: 'twitter:card', content: 'summary_large_image'},
      {name: 'twitter:title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
      {name: 'twitter:description', content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value}
    ]

    // if there's an image
    if (this.page.elements.page__main_image.value.length) {
      meta.push({property: 'og:image', content: this.page.elements.page__main_image.value[0].url})
      meta.push({name: 'twitter:image', content: this.page.elements.page__main_image.value[0].url})
    }

    // if there's a type
    if (this.page.elements.page__page_type.value.length) {
      meta.push({property: 'og:type', content: this.page.elements.page__page_type.value[0].name})
    }

    return {
      title: this.page.elements.page__page_title.value,
      meta: meta
    }
  },
  components: {BleedContainer, LandingPageWidgets, LandingPageHero},
  data () {
    return {
      page: null,
      modularContent: null
    }
  },
  mounted() {
    Analytics.trackPage('Custom Landing: /' + this.$route.params.slug)
    Kontent.getItem('custom_landing_page', this.$route.params.slug, 5).then((res) => {
      if (res.data.items.length) {
        this.page = res.data.items[0]
        this.modularContent = res.data.modular_content

        setTimeout(function(){window.prerenderReady = true}, 1000)
      } else {
        this.$router.push('/404')
      }

    })
  },
  methods: {
    login() {
      if (!this.$cookies.get('signup-source')) {
        this.$cookies.set('signup-source', 'Connect',null,'/');
      }
      this.$auth.loginWithRedirect({
        appState: { targetUrl: '/home' }
      });
    },
    register() {
      if (!this.$cookies.get('signup-source')) {
        this.$cookies.set('signup-source', 'Connect',null,'/');
      }
      this.$auth.loginWithRedirect({
        screen_hint: 'signup',
        appState: { targetUrl: '/home' }
      });
    }
  },
  computed: {
    language() {
      return this.page && this.page.elements.custom_language && this.page.elements.custom_language.value.length ? this.page.elements.custom_language.value[0].codename : 'english'
    }
  }
}
</script>

<style lang="scss" scoped>
.white-section {
  position: relative;
  z-index: 1;
  background-color: #fff;
  color: #000;
  padding: 80px 0;
  margin-top: 100px;
  margin-bottom: -6rem;

  @media(min-width: 1024px)
  {
    padding: 130px 0;
    margin-top: 150px;
  }

  .inner-container {
    position: relative;
  }

  .image {
    margin-top: -120px;
    margin-bottom: 30px;
    text-align: right;

    @media(min-width: 768px)
    {
      margin-bottom: -90px;
    }

    @media(min-width: 1024px)
    {
      margin-top: -190px;
      margin-bottom: -190px;
      padding-right: 40px;
    }

    img {
      display: inline-block;
      width: 128px;

      @media(min-width: 768px)
      {
        width: 160px;
      }

      @media(min-width: 1024px)
      {
        width: 270px;
      }
    }
  }

  .heading {

    padding-left: 10%;
    color: #000;

    @media(min-width: 768px)
    {
      padding-right: 55%;
    }

    font-size: 24px;
    font-weight: bold;
    line-height: 1.1;

    @media(min-width: 768px)
    {
      font-size: 40px;
    }

    @media(min-width: 1024px)
    {
      font-size: 56px;
    }
  }

  .description {
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 30px;

    @media(min-width: 768px)
    {
      margin-top: 50px;
      padding-left: 16%;
      padding-right: 35%;
    }

    @media(min-width: 1024px)
    {
      margin-top: 60px;
      font-size: 20px;
    }
  }

  .cta {
    line-height: 1;

    margin-top: 30px;

    @media(min-width: 768px)
    {
      margin-top: 60px;
    }
  }

  .images {
    text-align: right;
    padding-left: 30%;
    position: relative;
    z-index: 2;

    @media(min-width: 768px)
    {
      padding-left: 50%;
    }

    img {
      display: inline-block;
    }

    .image1 {
      padding-left: 25%;
      padding-right: 25%;
      position: relative;
      z-index: 2;
    }

    .image2 {
      position: relative;
      z-index: 1;
      margin-top: -20%;
    }
  }
}
</style>

<style lang="scss">
.landing-page {
  font-size: 16px;
  .intro-section {
    position: relative;
    z-index: 2;
    max-width: 550px;
    margin-top: -50px;

    @media(min-width: 1024px)
    {
      margin-top: -150px;
      max-width: 670px;
    }
  }

  .intro-paragraph {
    font-size: 24px;
    font-weight: bold;

    @media(min-width: 1024px)
    {
      font-size: 34px;
    }
  }
  .curated-collections {
    .section-heading {
      font-weight: normal !important;
      font-size: 34px !important;
    }
  }
  .intro-container {
    .bleed-content {
      background-color: #000;
    }

    .bleed-wrapper {
      padding-top: 50px;
      padding-right: 20px;

      @media(min-width: 1024px)
      {
        padding-top: 110px;
      }
    }
  }
}
</style>
