<template>
  <div class="landing-page-register-promo">
    <div class="outer-container">
      <div class="inner-container">
        <div class="box">
          <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.9375 10.375H16.9062V7.28125C16.9062 3.71484 13.9414 0.75 10.375 0.75C6.76562 0.75 3.84375 3.71484 3.84375 7.28125V10.375H2.8125C1.65234 10.375 0.75 11.3203 0.75 12.4375V20.6875C0.75 21.8477 1.65234 22.75 2.8125 22.75H17.9375C19.0547 22.75 20 21.8477 20 20.6875V12.4375C20 11.3203 19.0547 10.375 17.9375 10.375ZM13.4688 10.375H7.28125V7.28125C7.28125 5.60547 8.65625 4.1875 10.375 4.1875C12.0508 4.1875 13.4688 5.60547 13.4688 7.28125V10.375Z" fill="#E8490F"/>
          </svg>
          <div class="box-heading">Log in to continue to CONNECT.<br>New user? Sign up below.</div>
          <div class="box-buttons">
            <button type="button" @click="register" class="btn-white flex-1">Sign Up</button>
            <button type="button" @click="login" class="btn-transparent flex-1">Login</button>
          </div>
          <div class="video-button">
            How can CONNECT support your business?<br>
            <button type="button" @click="showPopup" class="btn btn-text">Watch the video &gt;</button>
          </div>
          <div class="preview" @click="showPopup">
            <img src="@/assets/register-preview.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from '@/events/EventBus';
export default {
  name: 'LandingPageRegisterPromo',
  props: {
    page: {
      type: Object,
      default () {
        return null
      }
    },
    modularContent: {
      type: Object,
      default () {
        return null
      }
    }
  },
  methods: {
    register() {
      if (!this.$cookies.get('signup-source')) {
        this.$cookies.set('signup-source', 'Connect',null,'/');
      }
      this.$auth.loginWithRedirect({
        screen_hint: 'signup',
        appState: { targetUrl: '/home' }
      });
    },
    login() {
      this.$auth.loginWithRedirect({
        appState: { targetUrl: this.$route.fullPath }
      });
    },
    showPopup() {
      Bus.$emit('show-register-popup')
    }
  }
}
</script>

<style lang="scss" scoped>
.landing-page-register-promo {
  padding-bottom: 60px;
}

.video-button {
  margin-top: 30px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.4;
  @media(min-width: 768px)
  {
    font-size: 24px;
    margin-top: 40px;
  }

  button {
    font-size: 14px;
    text-transform: none;
    font-weight: bold;
  }
}

.preview {
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: -60px;
  margin-top: 30px;
  max-width: 520px;
  z-index: 1;

  img {
    display: block;
    width: 100%;
  }

  @media(min-width: 1024px)
  {
    position: absolute;
    right: 30px;
    margin: 0;
    padding: 0;
    width: 400px;
    bottom: -30px;
  }

  @media(min-width: 1200px)
  {
    width: 600px;
    bottom: -60px;
  }
}
.box-buttons {
  margin-top: 20px;
  max-width: 600px;
  position: relative;
  z-index: 2;

  button {
    width: 100%;
    margin-top: 10px;

    @media(min-width: 660px)
    {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  @media(min-width: 660px)
  {
    box: horizontal middle center;
    margin-left: -5px;
    margin-right: -5px;
  }
}
.box-heading {
  box: horizontal middle left;
  font-size: 29px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
  margin-top: 20px;
  max-width: 720px;
  position: relative;
  z-index: 2;
  @media(min-width: 768px)
  {
    font-size: 42px;
  }
}
.box {
  background-color: #232323;
  margin-left: -10px;
  margin-right: -10px;
  padding: 30px;
  position: relative;

  @media(min-width: 1024px)
  {
    padding-bottom: 60px;
  }

  @media(min-width: 1300px)
  {
    margin-left: -20px;
    margin-right: -20px;
  }
}
</style>
