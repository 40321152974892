<template>
  <div class="outer-container">
    <div class="inner-container">
      <div class="landing-page-heading" :class="page.elements.heading_size.value[0].codename">{{page.elements.heading.value}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingPageHeading',
  props: {
    page: {
      type: Object,
      default () {
        return null
      }
    },
    modularContent: {
      type: Object,
      default () {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.landing-page-heading {
  margin-top: -40px;
  margin-bottom: -40px;

  @media (min-width: 768px) {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  padding-top: 80px;
  padding-bottom: 30px;
}

.section_heading {
  font-size: 34px;
}
</style>
