<template>
  <div class="landing-page-sponsors">
    <div class="outer-container">
      <div class="inner-container">
        <div class="section-heading">{{page.elements.heading.value}}</div>
        <sponsor-links :sponsors="page.elements.sponsors.value" :modular_content="modularContent" :show-heading="false" />
      </div>
    </div>
  </div>
</template>

<script>
import SponsorLinks from "@/components/SponsorLinks";
export default {
  name: 'LandingPageSponsors',
  components: {SponsorLinks},
  props: {
    page: {
      type: Object,
      default () {
        return null
      }
    },
    modularContent: {
      type: Object,
      default () {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.landing-page-sponsors {

}

.section-heading {
  font-size: 34px;
  margin-bottom: 30px;
}
</style>
