<template>
  <div class="landing-page-widgets">
    <div class="widget" v-for="widget in widgets" :key="modularContent[widget].system.id">
      <component :is="modularContent[widget].system.type" :page="modularContent[widget]" :modular-content="modularContent" />
    </div>
  </div>
</template>

<script>
import lp_text from "@/components/LandingPageText";
import lp_images from "@/components/LandingPageImages";
import lp_cta from "@/components/LandingPageCta";
import lp_events from "@/components/LandingPageEvents";
import lp_stories from "@/components/LandingPageStories";
import lp_collections from "@/components/LandingPageCollections";
import lp_sponsors from "@/components/LandingPageSponsors";
import lp_register_promo from "@/components/LandingPageRegisterPromo";
import lp_video from "@/components/LandingPageVideo";
import lp_divider from "@/components/LandingPageDivider";
import lp_heading from "@/components/LandingPageHeading";
import lp_spacer from "@/components/LandingPageSpacer";
import lp_bios from "@/components/LandingPageBios";
export default {
  name: 'LandingPageWidgets',
  components: {lp_register_promo, lp_sponsors, lp_collections, lp_stories, lp_events, lp_cta, lp_images, lp_text, lp_video, lp_divider, lp_heading, lp_spacer, lp_bios},
  props: {
    widgets: {
      type: Array,
      default () {
        return []
      }
    },
    modularContent: {
      type: Object,
      default () {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.landing-page-widgets {

}

.widget {
  margin-top: 40px;

  @media(min-width: 768px)
  {
    margin-top: 100px;
  }
}
</style>
