<template>
  <div class="landing-page-spacer" :class="'spacer-' + page.elements.size.value[0].codename"></div>
</template>

<script>
export default {
  name: 'LandingPageSpacer',
  props: {
    page: {
      type: Object,
      default () {
        return null
      }
    },
    modularContent: {
      type: Object,
      default () {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .landing-page-spacer {
    margin-top: -40px;
    margin-bottom: -40px;

    @media (min-width: 768px) {
      margin-top: -100px;
      margin-bottom: -100px;
    }
  }

  .spacer-small {
    height: 20px;
  }

  .spacer-medium {
    height: 60px;
  }

  .spacer-large {
    height: 100px;
  }
</style>
