<template>
  <div class="landing-page-events">
    <div class="outer-container">
      <div class="inner-container">
        <div class="section-heading">{{page.elements.heading.value}}</div>
        <div class="results">
          <div class="events">
            <div @click="setFormValues" class="bigmarker-event" v-for="(event, index) in page.elements.events.value" :key="`event${index}`">
              <component :is="'style'" type="text/css">
                #bigmarker-conference-widget-container{{modularContent[event].elements.bigmarker_id.value}} .bigmarker-widget-img-box {
                background-image: url({{modularContent[event].elements.image.value[0].url}}?w=500&auto=format);
                }
                #bigmarker-conference-widget-container{{modularContent[event].elements.bigmarker_id.value}} .bigmarker-widget-webinar-title:after {
                display: block;
                content: '{{modularContent[event].elements.description.value}}';
                font-size: 14px;
                margin-top: 10px;
                }
              </component>
              <div :id="`bigmarker-conference-widget-container${modularContent[event].elements.bigmarker_id.value}`"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'LandingPageEvents',
  props: {
    page: {
      type: Object,
      default () {
        return null
      }
    },
    modularContent: {
      type: Object,
      default () {
        return null
      }
    }
  },
  mounted() {
    for (let i = 0; i < this.page.elements.events.value.length; i++) {
      const event = this.page.elements.events.value[i];
      const bmId = this.modularContent[event].elements.bigmarker_id.value
      const head = document.getElementsByTagName('head')[0];
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://www.bigmarker.com/widget/register_widget.js?club=WA_connect&conference=${bmId}&widget_type=image_register&series_register=&upcoming_sub_title=&live_sub_title=&rec_sub_title=&upcoming_button_text=Register&live_button_text=Register&rec_button_text=View&link_to_channel=false&widget_width=&widget_height=&enable_iframe=false&background_color=ffffff&btext_color=2d374d&link_color=ff0000&ltext_color=ffffff&redirect_to_confirmation_page=false&widget_button_registered_content=View&cid=b56aa30b39d3`;
      head.appendChild(script);
    }
  },
  methods: {
    setFormValues () {
      if (this.$auth.isAuthenticated) {
        $('.bigmarker-widget-out-box').each((idx, widget) => {
          const el = $(widget)
          el.find('#new_member_first_name').val(this.$auth.user['https://australianwine.com/first_name'])
          el.find('#new_member_last_name').val(this.$auth.user['https://australianwine.com/last_name'])
          el.find('#new_member_full_name').val(this.$auth.user['https://australianwine.com/first_name'] + ' ' + this.$auth.user['https://australianwine.com/last_name'])
          el.find('#new_member_email').val(this.$auth.user.email)
          // el.find('#conference_registration_pre_conference_responses_attributes_0_response').val(this.$auth.user['https://australianwine.com/job_role'])
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.landing-page-events {

}

.results {
  margin-top: 30px;
}

.bigmarker-event {
  padding: 10px;
  width: 100%;
  font-family: 'Hellix', 'sans-serif' !important;
}

.events {
  @media(min-width: 768px)
  {
    box: horizontal wrap;
    margin: -10px;

    .bigmarker-event {
      width: 50%;
      box: horizontal;

      > div {
        width: 100%;
        height: 100%;
        background-color: #121212;
      }
    }
  }
}

.section-heading {
  font-size: 34px;
}
</style>

<style lang="scss">
.bigmarker-widget-webinar-btn {
  display: none !important;
}
.bigmarker-widget-out-box {
  max-width: none !important;
  height: auto !important;
}
.bigmarker-widget-box {
  background-color: #121212 !important;
  border: none !important;
  color: #FFF !important;
  padding: 0 0 30px 30px !important;
  display: block !important;

  @media(min-width: 1024px)
  {
    padding: 0 0 50px 50px !important;
  }
}
.bigmarker-widget-title {
  display: none !important;
}
.bigmarker-widget-img-box {
  position: relative !important;
  padding: 0 0 36% 0 !important;
  background-size: cover !important;
  background-position: center center !important;
  &:after {
    display: block;
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    height: 1px;
    transition: all 0.3s;
    background-color: #E8490F;
    opacity: 0;
    width: 70%;
  }

  &:hover {
    &:after {
      height: 5px;
      opacity: 1;
    }
  }
}

.bigmarker-widget-img {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  max-width: none !important;
  width: auto !important;
  opacity: 0 !important;
}
.bigmarker-widget-noimg-box {
  width: auto !important;
  height: auto !important;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  display: block !important;
  background: #222222 !important;
  font-family: 'Hellix', 'sans-serif' !important;
}
.bigmarker-widget-noimg-box-cell {
  opacity: 0.1 !important;
  width: auto !important;
  height: auto !important;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  display: block !important;

  a {
    width: auto !important;
    height: auto !important;
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    display: block !important;
  }
}
.bigmarker-widget-webinar-title {
  margin-top: 20px !important;
  padding: 0 !important;

  a {
    color: #FFF !important;
    font-family: 'Hellix', 'sans-serif' !important;
    font-size: 22px !important;
    font-weight: bold !important;
    line-height: 1.4 !important;
    transition: color 0.3s ease !important;
    @media(min-width: 1024px)
    {
      font-size: 34px !important;
    }

    &:hover {
      color: #E8490F !important;
    }
  }
}
.bigmarker-widget-webinar-date {
  font-size: 16px !important;
  color: #FFF !important;
  margin-top: 10px;
  font-family: 'Hellix', 'sans-serif' !important;
  @media(min-width: 1024px)
  {
    font-size: 20px !important;
  }
}
.bigmarker-widget-webinar-btn {
  position: relative !important;
  padding: 0 !important;
  margin-top: 20px !important;

  //left: 30px !important;
  //right: 30px !important;
  //bottom: 30px !important;
  @media(min-width: 1024px)
  {
    //left: 50px !important;
    //bottom: 50px !important;
  }

  a {
    font-family: 'Hellix', 'sans-serif' !important;
    box-shadow: inset 0 0 0 2px #E8490F !important;
    transition: all 0.3s ease !important;
    padding: 0 20px !important;
    color: #FFF !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    height: auto !important;
    background-color: transparent !important;
    text-transform: uppercase;
    line-height: 50px !important;
    display: inline-block !important;
    width: auto !important;
    border-radius: 0 !important;

    &:hover {
      box-shadow: inset 0 0 0 6px #E8490F !important;
    }
  }
}

.bigmarker-widget-more-link {
  position: absolute !important;
  padding: 0 !important;
  left: 170px !important;
  bottom: 30px !important;
  display: none !important;
  @media(min-width: 1024px)
  {
    left: 200px !important;
    bottom: 50px !important;
  }

  a {
    font-family: 'Hellix', 'sans-serif' !important;
    transition: all 0.3s ease !important;
    padding: 10px 20px !important;
    color: #959595 !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    height: auto !important;
    background-color: transparent !important;
    text-transform: uppercase;
    line-height: 50px !important;
    display: block !important;
    transition: color 0.3s ease !important;

    &:hover {
      color: #FFF !important;
    }
  }
}
.bigmarker-widget-modal-bg {
  background-color: rgba(#000, 0.9);
  box: horizontal middle center;
}
</style>
