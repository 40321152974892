<template>
  <div class="outer-container">
    <div class="inner-container">
      <div class="landing-page-divider bg-grey-200" :class="'divider-width-' + page.elements.width.value[0].codename + ' divider-height-' + page.elements.height.value[0].codename"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingPageDivider',
  props: {
    page: {
      type: Object,
      default () {
        return null
      }
    },
    modularContent: {
      type: Object,
      default () {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.landing-page-divider {
  margin: 0 auto;
}

.divider-width-small {
  width: 50px;
}

.divider-width-medium {
  width: 50%;
}

.divider-width-large {
  width: 100%;
}

.divider-height-small {
  height: 1px;
}

.divider-height-medium {
  height: 5px;
}

.divider-height-large {
  height: 10px;
}
</style>
