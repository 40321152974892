<template>
  <div class="landing-page-images">
    <div class="outer-container">
      <div class="inner-container">
        <div class="image image1"><img :src="`${page.elements.left_image.value[0].url}?w=1000&auto=format`" :alt="page.elements.left_image.value[0].description"></div>
        <div class="image image2"><img :src="`${page.elements.right_image.value[0].url}?w=1000&auto=format`" :alt="page.elements.left_image.value[0].description"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingPageImages',
  props: {
    page: {
      type: Object,
      default () {
        return null
      }
    },
    modularContent: {
      type: Object,
      default () {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.landing-page-images {
  position: relative;
  padding-bottom: 50px;
}

.image {
  width: 55%;
  height: 0;
  padding-bottom: 40%;
  position: relative;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.image2 {
  position: absolute;
  top: 50px;
  right: 20px;
}

.inner-container {
  position: relative;
}
</style>
