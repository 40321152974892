<template>
  <div class="landing-page-bios">
    <div class="outer-container">
      <div class="inner-container">

        <h2 v-if="page.elements.heading && page.elements.heading.value.length">{{page.elements.heading.value}}</h2>

        <div class="speakers-list mt-12 py-12 border-t border-b border-grey-200">
          <div class="flex flex-wrap -m-8">
            <div class="speaker w-full md:w-1/4 lg:w-1/6 p-8" v-for="speaker in page.elements.bios.value" :key="speaker">
              <div class="speaker-overlay fixed z-100 inset-0 bg-black bg-opacity-90 flex items-center justify-center" :class="currentSpeaker === speaker ? 'active' : 'inactive'">
                <div class="speaker-overlay-content relative p-6 bg-white text-main mx-auto w-full max-w-2xl">
                  <button @click="setCurrentSpeaker(null)" class="z-20 bg-white close absolute right-0 top-0 w-20 h-20 flex items-center justify-center">
                    <svg width="16" height="16" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.2754 11.875L21.6582 4.56055C22.0684 4.15039 22.0684 3.39844 21.6582 2.98828L19.9492 1.2793C19.5391 0.869141 18.7871 0.869141 18.377 1.2793L11.0625 8.66211L3.67969 1.2793C3.26953 0.869141 2.51758 0.869141 2.10742 1.2793L0.398438 2.98828C-0.0117188 3.39844 -0.0117188 4.15039 0.398438 4.56055L7.78125 11.875L0.398438 19.2578C-0.0117188 19.668 -0.0117188 20.4199 0.398438 20.8301L2.10742 22.5391C2.51758 22.9492 3.26953 22.9492 3.67969 22.5391L11.0625 15.1562L18.377 22.5391C18.7871 22.9492 19.5391 22.9492 19.9492 22.5391L21.6582 20.8301C22.0684 20.4199 22.0684 19.668 21.6582 19.2578L14.2754 11.875Z" fill="#000"/>
                    </svg>
                  </button>
                  <div class="row z-10">
                    <div class="image relative">
                      <img class="block w-full" :src="modularContent[speaker].elements.avatar.value[0].url" :alt="modularContent[speaker].elements.avatar.value[0].description">
                    </div>
                    <div class="copy mt-6">
                      <h5 class="text-black">{{modularContent[speaker].elements.full_name.value}}</h5>
                      <div class="title block main-text text-sm">
                        {{modularContent[speaker].elements.title.value}}
                      </div>
                      <div class="bio mt-4 text-black" v-html="modularContent[speaker].elements.bio.value"></div>
                    </div>
                  </div>
                </div>
              </div>

              <button type="button" class="w-full group" @click="setCurrentSpeaker(speaker)">
              <span class="block avatar rounded-full w-full overflow-hidden isolate relative mx-auto">
                <img class="block absolute left-0 top-0 w-full h-full object-cover" :src="modularContent[speaker].elements.avatar.value[0].url" :alt="modularContent[speaker].elements.avatar.value[0].description">
              </span>
                <span class="name mt-6 block text-lg font-bold uppercase text-primary-500 group-hover:text-white">
                {{modularContent[speaker].elements.full_name.value}}
              </span>
                <span class="title block main-text text-sm">
                {{modularContent[speaker].elements.title.value}}
              </span>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingPageBios',
  props: {
    page: {
      type: Object,
      default () {
        return null
      }
    },
    modularContent: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data() {
    return {
      currentSpeaker: null
    }
  },
  methods: {
    setCurrentSpeaker(speaker) {
      this.currentSpeaker = speaker
    }
  }
}
</script>

<style lang="scss" scoped>
.speaker {
  .bio {
    p {
      margin: 10px 0;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.speaker-overlay-content {
  max-height: 70vh;
  overflow: auto;
}

.speaker {
  .avatar {
    aspect-ratio: 1/1;
    max-width: 200px;
  }

  img {
    transition: all 0.5s ease;
  }

  button:hover {
    img {
      transform: scale(1.05);
    }
  }

  .speaker-overlay {
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
}

.main-text {
  color: #9A9A9A;
}

h2 {
  font-size: 34px;
  font-weight: 400;
}
</style>