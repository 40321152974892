<template>
  <div class="landing-page-stories">
    <div class="outer-container">
      <div class="inner-container">
        <div class="section-heading">{{page.elements.heading.value}}</div>
        <div class="stories">
          <div class="single-image-copy story" :class="index % 2 === 0 ? 'bleed-left' : 'bleed-right'" v-for="(story, index) in page.elements.stories.value" :key="modularContent[story].system.id">
            <div class="image">
              <bleed-container :bleed="index % 2 === 0 ? 'left' : 'right'">
                <img :src="`${modularContent[story].elements.image.value[0].url}?w=1000&auto=format`" :alt="modularContent[story].elements.image.value[0].description">
              </bleed-container>
            </div>
            <div class="content">
              <div class="heading">{{modularContent[story].elements.heading.value}}</div>
              <div class="text" v-html="modularContent[story].elements.content.value"></div>
              <div class="cta" v-if="modularContent[story].elements.cta_text.value && !modularContent[story].elements.cta_type.value.length">
                <router-link class="btn-white btn-wide inline-block" :to="modularContent[story].elements.cta_url.value" v-if="modularContent[story].elements.cta_url.value.substring(0, 1) === '/' && (modularContent[story].elements.cta_type.value.length === 0 || modularContent[story].elements.cta_type.value[0].codename === 'same_window')">{{modularContent[story].elements.cta_text.value}}</router-link>
              </div>
              <div class="cta" v-if="modularContent[story].elements.cta_text.value && modularContent[story].elements.cta_type.value.length">
                <router-link class="btn-white btn-wide inline-block" :to="modularContent[story].elements.cta_url.value" v-if="modularContent[story].elements.cta_url.value.substring(0, 1) === '/' && modularContent[story].elements.cta_type.value[0].codename === 'same_window'">{{modularContent[story].elements.cta_text.value}}</router-link>
                <a class="btn-white btn-wide inline-block" :href="modularContent[story].elements.cta_url.value" v-else :target="{'_blank': modularContent[story].elements.cta_type.value[0].codename === 'new_window', '_self': modularContent[story].elements.cta_type.value[0].codename === 'same_window'}">{{modularContent[story].elements.cta_text.value}}</a>
              </div>
            </div>
          </div>
      </div>
    </div>

    </div>
  </div>
</template>

<script>
import BleedContainer from "@/components/BleedContainer";
export default {
  name: 'LandingPageStories',
  components: {BleedContainer},
  props: {
    page: {
      type: Object,
      default () {
        return null
      }
    },
    modularContent: {
      type: Object,
      default () {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.landing-page-stories {

}

.cta {
  margin-top: 30px;
}

.single-image-copy
{
  .content {
    margin-top: 10px;
    @media(min-width: 768px)
    {
      margin-top: 0;
    }
  }
  h3 {

    font-weight: 700;
    margin-bottom:40px;
  }
  @media(min-width: 768px) {
    display:flex;
    align-items: center;
    flex-direction:row;

    .image
    {
      width:55%;
      height:300px;
      position: relative;

      .bleed-container
      {
        height:100%;
      }

      img
      {
        object-fit:cover;
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
      }
    }

    .content
    {
      width:50%;
      padding-left:60px;
    }
  }

  &.bleed-right {
    @media(min-width: 768px) {
      flex-direction:row-reverse;

      .content
      {
        width:50%;
        padding-right:60px;
      }
    }
  }
}

.stories {
  margin-top: 30px;
}

.section-heading {
  font-size: 34px;
}

.story {
  font-size: 14px;
  font-weight: bold;
  margin-top: 60px;

  &:first-child {
    margin-top: 0;
  }

  .heading {
    font-size: 32px;
  }

  .text {
    margin-top: 10px;
  }

  .image {
    height: 180px;

    @media(min-width: 768px)
    {
      height: 250px;
    }

    @media(min-width: 1024px)
    {
      height: 450px;
    }

    .bleed-container {
      height: 100%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.bleed-left {
  .image {
    margin-right: -20px;
  }
}

.bleed-right {
  .image {
    margin-left: -20px;
  }
}

</style>
